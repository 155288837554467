<template>
    <slot v-bind:dateText="dateText">
        <p>{{ dateText }}</p>
    </slot>
</template>

<script setup lang="ts">
import dayjs from "dayjs"

const { date } = defineProps<{ date: Date | string }>();
const dateValue = ref(new Date(date))
const dateText = computed(() => dayjs(dateValue.value).format("DD/MM/YYYY"))

</script>